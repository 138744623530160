
export const IntegrationConfigMixin = {
    data() {
        return {
            loading: false,
            stages: [
                'ABRAPA_REGISTER_FIACAO',
                'ABRAPA_REGISTER_TECELAGEM',
                'ABRAPA_REGISTER_CONFECCAO'
            ]
        }
    },
};
