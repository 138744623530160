<template>
  <v-dialog v-model="internalDialog" persistent max-width="640px">
    <v-card style="border-top: 6px solid #03AC8C">
      <v-card-title>
        {{  $t("abrapa.titles.orderRegistration") }}
      </v-card-title>
      <v-card-text class="mt-6">
        <v-form ref="form" v-model="valid">
          <!-- Locations and Store Selections -->
          <v-row>
            <v-col cols="6" offset="6" class="py-0">
              <v-datetime-picker
                ref="datetimePicker"
                v-model="computedDate"
                :label="$t('abrapa.fields.date')"
                :locale="$i18n.locale"
                :required="true"
                :show-date="true"
                :show-time="true"
                :text-field-props="textProps"
                color="secondary"
                date-format="dd / MM / yyyy"
                time-format="HH:mm"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <SimpleLocationFilters
                ref="origin"
                :location-label="$t('records.fields.location_origin')"
                location-ref="originLocation"
                @update:location="location = $event"
                :storage-label="$t('records.fields.originReg')"
                storage-ref="originStorage"
                @update:storage="originSegundaryLocation = $event ? $event.gln : null"
                @on:valid-form="validOrigin = $event"
                :show-origin="true"
                :show-destination="true"
                :autocomplete-props="{
                  color: 'secondary',
                  itemColor: 'secondary',
                  smallChips: true,
                  clearable: true,
                  multiple: false,
                  returnObject: true,
                  dense: true,
                  outlined: true,
                  required: true
                }"
              />
              <SimpleLocationFilters
                ref="destination"
                :location-label="$t('records.fields.location_destination')"
                location-ref="destinationLocation"
                @update:location="storage = $event"
                :storage-label="$t('records.fields.destinationRed')"
                storage-ref="destinationStorage"
                @update:storage="destinySegundaryLocation = $event ? $event.gln : null"
                @on:valid-form="validDestination = $event"
                :show-origin="true"
                :show-destination="true"
                :autocomplete-props="{
                  color: 'secondary',
                  itemColor: 'secondary',
                  smallChips: true,
                  clearable: true,
                  multiple: false,
                  returnObject: true,
                  dense: true,
                  outlined: true,
                  required: true
                }"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                v-model="selectedStore"
                :items="stores"
                item-value="api_key"
                outlined
                dense
                color="secondary"
                item-color="secondary"
                :rules="requireRules"
              >
                <template v-slot:label>
                  {{ $t("abrapa.fields.store") }}
                  <strong class="red--text">*</strong>
                </template>
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      {{ item.name +" ("+ item.url +")" }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.name +" ("+ item.url +")" }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <!-- Products Dynamic Addition -->
          <v-expansion-panels flat v-model="panel">
            <v-expansion-panel
              v-for="(product, index) in g_product"
              :key="index"
              class="my-2"
              :class="[{ 'bordered': (index === panel) }, { 'non-bordered': (index !== panel) }]"
            >
              <v-expansion-panel-header hide-actions>
                <h3 class="font-weight-medium">{{ product.name || $t('abrapa.fields.product') + ` #${index+1}` }}</h3>
                <v-spacer />
                <span class="d-flex justify-end">
                  <v-btn @click.stop="deleteProduct(index)"
                         tile
                         small
                         depressed
                         elevation="0"
                         color="error"
                         class="expanding-button__delete ml-12"
                         style="border-radius: .2rem"
                  >
                    <v-icon small>mdi-close</v-icon>
                    <span class="button-text">{{ $t('general.buttons.delete') }}</span>
                  </v-btn>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- Product Details -->
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field
                      v-model="product.name"
                      outlined
                      dense
                      color="secondary"
                      :rules="requireRules"
                    >
                      <template v-slot:label>
                        {{ $t("abrapa.fields.name") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field
                      v-model="product.category"
                      outlined
                      dense
                      color="secondary"
                      :rules="requireRules"
                    >
                      <template v-slot:label>
                        {{ $t("abrapa.fields.category") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12">
                    <v-text-field
                      v-model="product.gtin"
                      outlined
                      dense
                      color="secondary"
                      @input="clearPoints($event, product)"
                      @keyup="validGtin14($event, product)"
                      @keydown="validGtin14Delete($event, product)"
                      :rules="requireAndGTIN14Rules(13)"
                    >
                      <template v-slot:label>
                        {{ $t("abrapa.fields.gtin") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12">
                    <v-textarea
                      v-model="product.description"
                      :label="$t('abrapa.fields.description')"
                      outlined
                      dense
                      color="secondary"
                      counter
                      maxlength="250"
                      :rules="lengthRules(250).concat(limitEnterKeyPress)"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="d-flex align-center align-content-center">
                      <v-chip small color="secondary" class="font-weight-bold">{{ product.cotton_percent + ' %' }}</v-chip>
                      <p class="ma-0 pa-0 mx-2">{{ $t('abrapa.fields.cotton_percent') }}</p>
                    </div>
                    <v-slider
                      v-model="product.cotton_percent"
                      :max="100"
                      :min="0"
                      outlined
                      dense
                      color="secondary"
                      track-color="secondary lighten-3"
                      :rules="requireRules"
                    >
                      <template #prepend>
                        <v-chip small disabled>0</v-chip>
                      </template>
                      <template #append>
                        <v-chip small disabled>100</v-chip>
                      </template>
                    </v-slider>
                  </v-col>
                  <v-col class="py-0" cols="12"  md="6">
                    <v-text-field
                      v-model="product.lot"
                      outlined
                      dense
                      color="secondary"
                      :rules="requireAndLotRules"
                    >
                      <template v-slot:label>
                        {{ $t("abrapa.fields.lot") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field
                      v-model="product.quantity"
                      label="Quantity"
                      type="number"
                      :rules="requireRules.concat(onlyPositiveNumberFormat)"
                      outlined
                      dense
                      color="secondary"
                    >
                      <template v-slot:label>
                        {{ $t("abrapa.fields.quantity") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-autocomplete
                      v-model="product.unit"
                      :items="units"
                      item-text="name"
                      item-value="code"
                      outlined
                      dense
                      color="secondary"
                      item-color="secondary"
                      :rules="requireRules"
                    >
                      <template v-slot:label>
                        {{ $t("abrapa.fields.unit") }}
                        <strong class="red--text">*</strong>
                      </template>
                      <template v-slot:item="{ item, on, attrs }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-content>
                            {{ item.name }}
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row>
            <v-col cols="12" align-self="end" class="d-flex justify-end">
              <v-btn text class="my-2" color="secondary" @click="addProduct">{{ $t("general.buttons.add") + " " + $t("abrapa.fields.product") }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-4 px-8">
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close" :disabled="loading">{{ $t("general.buttons.close") }}</v-btn>
        <v-btn color="secondary darken-1" text @click="save" :disabled="isValid" :loading="loading">{{ $t("general.buttons.confirm") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { IntegrationConfigMixin } from "@/module/integrations/abrapa/mixins/integration-config.mixin";
  import { IntegrationDialogMixin } from "@/module/integrations/abrapa/mixins/integration-dialog.mixin";
  import { GtinMixin } from "@/mixins/gtin.mixin";
  import SimpleLocationFilters from "@/components/filters/SimpleLocationFilters.vue";
  import LanguageService from "@/services/LanguajeService";
  import { mapActions } from "vuex";
  import moment from "moment/moment";

  export default {
    name: "IntegrationOrderDialog",
    components: { SimpleLocationFilters },
    mixins: [IntegrationConfigMixin, IntegrationDialogMixin, GtinMixin],
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        valid: false,
        validOrigin: false,
        validDestination: false,
        internalDialog: this.value,
        panel: null,
        date: null,
        location: null,
        originSegundaryLocation: null,
        storage: null,
        destinySegundaryLocation: null,
        stores: [],
        units: [],
        g_product: [],
        selectedOriginLocation: null,
        selectedDestinyLocation: null,
        selectedStore: null,
        textProps: {
          persistentHint: true,
          small: true,
          flat: true,
          outlined: true,
          dense: true,
          clearable: true,
          color: "secondary",
          itemColor: "secondary",
        },
        showAlert: false,
        uploadProgress: 0,
        loading: false
      };
    },
    watch: {
      value(val) {
        this.internalDialog = val;
      },
      internalDialog(val) {
        this.$emit('input', val);
      }
    },
    async created() {
      this.stores = await this.fetchStores();
      this.units = await this.fetchUnits();
    },
    computed: {
      isValid() {
        return !(this.valid && this.validOrigin && this.validDestination && this.g_product.length > 0);
      },
      computedDate: {
        get() {
          return this.date
            ? new Date(this.date)
            : null
        },
        set(data) {
          this.date = data ? new Date(data) : null;
        },
      },
    },
    methods: {
      ...mapActions({
        createOrder: "abrapa/CREATE_ORDER",
        fetchStores: "abrapa/FETCH_STORES",
        fetchUnits: "abrapa/FETCH_UNITS"
      }),
      addProduct() {
        const newProduct = {
          name: "",
          gtin: "",
          description: "",
          category: "",
          cotton_percent: 100,
          quantity: 0,
          unit: "",
          lot: ""
        };
        this.g_product.push(newProduct);
        this.panel = this.g_product.length - 1;
      },
      deleteProduct(index) {
        this.g_product.splice(index, 1);
        this.panel = null;
      },
      close() {
        this.$emit('input', false);
        this.computedDate = moment().format();
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.$refs.origin.clearLocation();
        this.$refs.destination.clearLocation();
        this.loading = false;
      },
      async save() {
        if (this.showAlert) return;

        if (this.valid && !this.loading) {
          this.loading = true;
          try {
            const productsWithLanguageKeys = this.g_product.map(product => ({
              ...product,
              name: LanguageService.setKey3(
                product.name, product.name, this.languageStructure
              ),
              description: LanguageService.setKey3(
                product.description, product.description, this.languageStructure
              ),
              category: LanguageService.setKey3(
                product.category, product.category, this.languageStructure
              ),
            }));

            const orderPayload = {
              date: moment(this.computedDate)
                .format()
                .slice(0, 19),
              originSegundaryLocation: this.originSegundaryLocation,
              destinySegundaryLocation: this.destinySegundaryLocation,
              store: this.selectedStore,
              g_product: productsWithLanguageKeys
            };

            console.log(orderPayload)

            await this.createOrder([orderPayload, false]);
            this.close();
          } catch (error) {
            console.error("Failed to save order:", error);
          } finally {
            this.loading = false;
          }
        }
      },
    }
  }
</script>

<style scoped>
.v-chip {
  border-radius: .3em !important;
}

.expanding-button__delete {
  position: relative;
  overflow: hidden;
  transition: width 0.3s ease;
  justify-content: flex-start;
}

.expanding-button__delete {
  width: 20px;
  padding-left: 18px !important;
}

.expanding-button__delete:hover {
  width: 105px;
}

.button-text {
  transition: transform, opacity  0.3s ease;
  opacity: 0;
  transform: translateX(-100%);
  display: inline-block;
}

.expanding-button__delete:hover .button-text {
  opacity: 1;
  transform: translateX(0%);
}

.bordered {
  border: 2px solid rgba(3, 172, 140, 0.3) !important;
  box-shadow: 0 4px 8px rgba(148, 148, 148, 0.3) !important;
}

.non-bordered {
  border: 1px solid rgba(0,0,0,0.37) !important;
}
</style>