import {mapActions, mapGetters} from "vuex";
import sortList from "@/services/OrderListService";
import _ from "lodash";

export const SimpleLocationMixin = {
  props: {
    showOrigin: {
      type: Boolean,
      required: false,
      default: false
    },
    showDestination: {
      type: Boolean,
      required: false,
      default: false
    },
    locationRef: {
      type: String,
      required: false
    },
    storageRef: {
      type: String,
      required: false
    },
    locationLabel: {
      type: String,
      required: false
    },
    storageLabel: {
      type: String,
      required: false
    },
    autocompleteProps: {
      type: Object,
      required: false,
      default: () => ({
        backgroundColor: "#EAEAEA80",
        color: "secondary",
        flat: true,
        itemColor: "secondary",
        small: true,
        smallChips: true,
        solo: true,
        clearable: true,
        multiple: false,
        returnObject: true
      })
    },
  },
  data: () => ({
    loadings: {
      origin: false,
      destination: false,
    },
    location: null,
    storageDevice: null,
    locationData: [],
    storageData: [],
    pagination: {
      locations: {
        currentPage: 1,
        itemsPerPage: 10,
      },
      storages: {
        currentPage: 1,
        itemsPerPage: 10,
      }
    },
    locationsSearch: '',
    storagesSearch: '',
    valid: true
  }),
  async created() {
    await this.fetchLocations()
    this.$set(this, 'locationData', this.sLocations);

    await this.fetchStorages(null, this.location)
    this.$set(this, 'storageData', this.sStorageDevices);
  },
  watch: {
    location(newValue, oldValue) {
      if (oldValue > newValue) {
        this.cleanupStorages();
      }
    },
    valid(newValue, oldValue) {
      this.$emit('on:valid-form', newValue);
    }
  },
  computed: {
    ...mapGetters({
      sLocations: "locations/sLocations",
      sStorageDevices: "storage/sStorageDevices",
      requireRules: "general/requireRules"
    }),

    displayedLocations() {
      const start = (this.pagination.locations.currentPage - 1) * this.pagination.locations.itemsPerPage;
      const end = start + this.pagination.locations.itemsPerPage;
      return this.filteredLocations.slice(start, end);
    },
    displayedStorages() {
      const start = (this.pagination.storages.currentPage - 1) * this.pagination.storages.itemsPerPage;
      const end = start + this.pagination.storages.itemsPerPage;
      return this.filteredStorages.slice(start, end);
    },
    filteredLocations() {
      if (!this.locationsSearch) {
        return this.locationData;
      }
      return this.locationData.filter(location =>
        location.name.toLowerCase().includes(this.locationsSearch.toLowerCase())
      );
    },
    filteredStorages() {
      if (!this.storagesSearch) {
        return this.storageData;
      }
      return this.storageData.filter(storage =>
        storage.control_label.toLowerCase().includes(this.storagesSearch.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions({
      fetchSLocations: "locations/fetchSLocations",
      fetchSStorageDevices: "storage/fetchSStorageDevices",
    }),
    updatePagination(property, { page, itemsPerPage }) {
      this.pagination[property].currentPage = page;
      if (itemsPerPage) {
        this.pagination[property].itemsPerPage = itemsPerPage;
      }
    },
    handleLocationsSearchChange(searchTerm) {
      this.locationsSearch = searchTerm;
      this.updatePagination('locations', { page: 1, itemsPerPage: this.pagination.locations.itemsPerPage });
    },

    handleStoragesSearchChange(searchTerm) {
      this.storagesSearch = searchTerm;
      this.updatePagination('storages', { page: 1, itemsPerPage: this.pagination.storages.itemsPerPage });
    },
    orderData(list, order) {
      return sortList.orderListByUppercase(list, order)
    },
    getByProperty(array, property) {
      return _.map(array, property)
    },
    async fetchLocations(e) {
      this.$set(this.loadings, "origin", true)

      const filters = {
        stringFilter: e || "",
        ids: this.location ? [this.location.locationId] : null,
        isActive: true,
      }

      await this.fetchSLocations([filters, this.$toast]).finally(() => {
        this.$set(this, 'locationData', this.sLocations);
        this.$set(this.loadings, "origin", false)
      })
    },
    async fetchStorages(e, pl) {
      if (pl) {
        this.$set(this.loadings, "destination", true);

        // Convertimos pl en una lista de IDs si son objetos
        if (typeof pl === "object") {
          pl = [this.location.locationId];
        }

        await this.fetchSStorageDevices([
          {
            primaryLocationIds: pl,
            stringFilter: e || "",
            isActive: true,
          },
          this.$toast,
        ]).finally(() => {
          this.pagination.storages.currentPage = 1;
          this.$emit('update:location', this.location);
          this.$set(this, 'storageData', this.sStorageDevices);
          this.$set(this.loadings, "destination", false);
        });
      }
    },
    cleanupStorages() {
      this.storageDevice = null;
    },
    clearLocation() {
      this.location = null;
      this.$emit('update:location', null);
      this.storageDevice = null;
      this.$emit('update:storage', null);
      this.$refs.form.reset();
    },
    clearStorage() {
      this.storageDevice = null;
      this.$emit('update:storage', null);
    },
  },
}