import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"card-integration bg-white d-flex flex-column justify-space-between",style:(`border-left: 4px solid ${_vm.card.color}60`),attrs:{"width":"270px"}},[_c('span',[_c(VCardTitle,[_c(VTooltip,{attrs:{"content-class":"title__tooltip","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_vm._v(_vm._s(_vm._f("truncate")(_vm.card.title,20)))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.card.title))])])],1),_c(VCardSubtitle,{staticClass:"py-0"},[_vm._v(_vm._s(_vm.card.subtitle))])],1),_c(VCardText,{staticClass:"d-flex flex-column overflow-hidden py-0"},[_vm._t("content")],2),_c(VCardActions,[_vm._t("actions")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }