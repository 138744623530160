<template>
  <div class="file-dropzone"
       @dragover.prevent="handleDragOver"
       @drop.prevent="handleFileDrop"
  >
    <v-btn v-if="!file" color="secondary" text @click="triggerFileInput">
      <v-icon left>mdi-upload</v-icon>
      {{ `${$t('abrapa.subtitles.dragAndDrop')} (${mimeTypes})` }}
    </v-btn>
    <div v-else class="file-info">
      <v-chip
        color="secondary"
        close
        class="ma-2"
        @click:close="clearFile">
        <v-icon left>mdi-file-document</v-icon>
        {{ file.name }}
      </v-chip>
    </div>
    <input type="file" ref="fileInput" @change="handleFileUpload" :accept="mimeTypes" style="display: none" />
  </div>
</template>

<script>
  export default {
    props: {
      mimeTypes: {
        type: String,
        required: false,
        default: '.xlsx'
      }
    },
    data() {
      return {
        file: null
      };
    },
    methods: {
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      handleDragOver(event) {
        event.preventDefault();
      },
      handleFileDrop(event) {
        event.preventDefault();
        const files = event.dataTransfer.files;
        this.processFiles(files);
      },
      handleFileUpload(event) {
        const files = event.target.files;
        this.processFiles(files);
      },
      processFiles(files) {
        if (files.length > 0) {
          this.file = files[0];
          this.$emit('file-uploaded', this.file);
        } else {
          alert(`Por favor, suba un archivo ${this.mimeTypes} válido.`);
        }
      },
      clearFile() {
        this.file = null;
        this.$emit('file-cleared');
        this.$refs.fileInput.value = null;
      }
    }
  }
</script>

<style scoped>
  .file-dropzone {
    border: 2px dashed #c9c9c9;
    border-radius: .3em;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  .file-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
