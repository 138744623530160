import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('p',{staticClass:"display-1 text--primary"},[_vm._v(" "+_vm._s(_vm.$t('menu.integrations'))+" ")])])],1),_c(VRow,{staticClass:"my-4 mx-1",attrs:{"justify":"start"}},_vm._l((_vm.cards),function(card,index){return _c('span',{key:index},[_c('CardWrapper',{staticClass:"mx-4",attrs:{"card":card},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(VIcon,{staticClass:"pa-6",attrs:{"x-large":"","color":card.color}},[_vm._v(_vm._s(card.icon))])]},proxy:true},{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"disabled":!_vm.check([{ domain: card.authority.domain, permisions: [card.authority.permission] }]),"block":"","elevation":"0","color":"secondary"},on:{"click":function($event){return _vm.openDialog(card.action)}}},[_vm._v(" "+_vm._s(_vm.$t(card.button))+" ")])]},proxy:true}],null,true)})],1)}),0),_c('IntegrationProductDialog',{model:{value:(_vm.dialog.product),callback:function ($$v) {_vm.$set(_vm.dialog, "product", $$v)},expression:"dialog.product"}}),_c('IntegrationOrderDialog',{model:{value:(_vm.dialog.order),callback:function ($$v) {_vm.$set(_vm.dialog, "order", $$v)},expression:"dialog.order"}}),_c('IntegrationImportOrdersDialog',{model:{value:(_vm.dialog.importOrders),callback:function ($$v) {_vm.$set(_vm.dialog, "importOrders", $$v)},expression:"dialog.importOrders"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }