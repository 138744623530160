import {mapGetters} from "vuex";

export const IntegrationDialogMixin = {
    data() {
        return {
            loading: false,
            stages: [
                'ABRAPA_REGISTER_FIACAO',
                'ABRAPA_REGISTER_TECELAGEM',
                'ABRAPA_REGISTER_CONFECCAO'
            ]
        }
    },
    computed: {
        ...mapGetters({
            requireRules: 'general/requireRules',
            selectRule: "general/selectRule",
            requireAndLotRules: "general/requireAndLotRules",
            lengthRules: "general/lengthRules",
            limitEnterKeyPress: "general/limitEnterKeyPress",
            requireAndGTIN14Rules: "general/requireAndGTIN14Rules",
            onlyPositiveNumberFormat: "general/onlyPositiveNumberFormat",
            languageStructure: "general/languageStructure",
        })
    },
    methods: {}
};
