import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","text":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-cog")]),_vm._v(" "+_vm._s(_vm.text)+" ")],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{attrs:{"width":"350"}},[_c(VCardText,[_c(VForm,{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_vm._t("fields"),(!(_vm.selected && _vm.selected.length > 0))?_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,_vm._b({attrs:{"type":"number","min":"1","rules":_vm.required},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("abrapa.fields.startRow"))+" "),_c('strong',{staticClass:"red--text"},[_vm._v("*")])]},proxy:true}],null,false,4184279007),model:{value:(_vm.startRow),callback:function ($$v) {_vm.startRow=$$v},expression:"startRow"}},'v-text-field',_vm.textProps,false))],1):_vm._e(),(!(_vm.selected && _vm.selected.length > 0))?_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,_vm._b({attrs:{"type":"number","max":_vm.length,"rules":_vm.required},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("abrapa.fields.endRow"))+" "),_c('strong',{staticClass:"red--text"},[_vm._v("*")])]},proxy:true}],null,false,3990206704),model:{value:(_vm.endRow),callback:function ($$v) {_vm.endRow=$$v},expression:"endRow"}},'v-text-field',_vm.textProps,false))],1):_vm._e()],2)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey","text":""},on:{"click":_vm.closeMenu}},[_vm._v(_vm._s(_vm.$t('general.buttons.close')))]),_c(VBtn,{attrs:{"color":"secondary","text":"","disabled":!_vm.valid},on:{"click":_vm.applySettings}},[_vm._v(_vm._s(_vm.$t('general.buttons.save')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }